import React, { useState } from "react"
import Auth from "../../utils/Auth"
import FetchClient from "../../utils/FetchClient"
import Spinner from "../spinner"

import styles from "./signup.module.css"

export default function SignupBlock() {
    const [input, setInput] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = e.target.type === 'checkbox' ? e.target.checked : value;
        setInput(Object.assign({}, input, object));
    }

    const _signIn = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setHasError(false);

        var data =  {
            'firstname': input.firstname,
            'email': input.email,
            'password': input.password,
            'mktg': input.mktgConsent
        };

        if(input.user_random !== 'undefined') {
            console.error('Stealth error');
        }

        FetchClient({
            method: 'post',
            data: data,
            url: `${process.env.GATSBY_API_URL}/auth/register`
        }).then(res => {
            if(res['authToken']) {
                Auth.signin(res['authToken']);
            }
            return res;
        })
        .then(res => {
            setTimeout(() => {
                // if the user is authenticated successfully...
                if(Auth.isAuthenticated()) {

                    window.analytics.identify(`vuid-${res.id}`, {
                        name: `User ${res.id}`,
                        subscription: res.role
                    });
                    window.analytics.track('Sign-up', { 'surface': 'landing' }, () => {
                        window.location.href = `${process.env.GATSBY_APP_URL}/getstarted`;
                        // cleanup
                        setIsLoading(false);   
                    });                    
                }
            }, 1500)
        })
        .catch(err => {
            if(!err.response)
                setHasError('Server error');

            var errorMsg;

            err.response.json().then(parsed => {
                setIsLoading(false);
                switch(err.response.status) {
                    case 400:
                        errorMsg = "An error occured. Please try again.";
                        break;
                    case 401:
                        errorMsg = "Wrong email or password. Please check your credentials and try again.";
                        break;
                    case 404:
                        errorMsg = "This user doesn't exist."
                        break;
                    case 500:
                        errorMsg = typeof parsed.message === 'object' ? Object.keys(parsed.message).map(k => `${parsed.message[k]} `) : parsed.message;
                        break;
                    default:
                        errorMsg = "An error occured. Please check the details provided and try again.";
                }
                setHasError(errorMsg);
            })
        })
    }

    return(
        <div id="footer-signup" className={styles.signup}>
            <div className="wrapper">
                <h2>Eat better. Eat Vegly</h2>
                <p className="lead">Get 1 plan free forever. No credit card required.</p>
                <div className={styles.formError}>{hasError}</div>
                <form className={styles.signupForm} onSubmit={_signIn}>
                    <div className={styles.inlineGroup}>
                        <div className="form-group">
                            <input type="text" placeholder="First name" name="firstname" required value={input['firstname'] || ''} onChange={_onChange}/>
                        </div>
                        <div className="form-group">
                            <input type="email" placeholder="Email address" name="email" required value={input['email'] || ''} onChange={_onChange}/>
                        </div>
                        <div className="form-group">
                            <input type="password" placeholder="Create a password" name="password" required value={input['password'] || ''} onChange={_onChange}/>
                        </div>
                        <div className="form-group" style={{display:"none"}}>
                            <input type="hidden" placeholder="Type some text" name="user_random" value={input['user_random'] || ''} onChange={_onChange}/>
                        </div>
                        <div className={styles.formActions}>
                            {isLoading ? 
                                <Spinner/>
                                :
                                <button type="submit" className="btn btn-xl">Sign up</button>
                            }
                        </div>
                    </div>
                    <div className={styles.tos}>
                        <input type="checkbox" id="marketingConsent" name="mktgConsent" checked={input['mktgConsent'] || ''} onChange={_onChange}/>
                        <label htmlFor="marketingConsent">I would like to receive the latest special OFFERS, updates and FREE tips from Vegly. I can unsubscribe at any time.</label>
                    </div>
                    <div className={styles.tos}>
                        <label htmlFor="tosConsent">By creating an account, you agree to the <a href="/terms">Terms of Service</a> and acknowledge our <a href="/privacy">Privacy Policy</a></label>
                    </div>
                </form>
            </div>
        </div>	
    )
}